import React, { Fragment, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';

const Register = ({ setAuth }) => {
    const [inputs, setInputs] = useState({
        email: '',
        password: '',
        name: '',
    });

    const { email, password, name } = inputs;

    const onChange = (e) => setInputs({ ...inputs, [e.target.name]: e.target.value });

    const onSubmitForm = async (e) => {
        e.preventDefault();
        try {
            const body = { email, password, name };
            const response = await fetch('https://www.jakubbaczkowski.com:5000/authentication/register', {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify(body),
            });
            console.log(response);
            const parseRes = await response.json();
            console.log('dziala');
            if (parseRes.token) {
                localStorage.setItem('token', parseRes.token);
                setAuth(true);
                toast.success('Registered successfully');
            } else {
                setAuth(false);
                toast.error('Error');
            }
        } catch (err) {
            console.error('zjebalo sie');
        }
    };

    return (
        <Fragment>
            <h1 className="mt-5 text-center">Register</h1>
            <form onSubmit={onSubmitForm}>
                <input
                    type="text"
                    name="email"
                    value={email}
                    placeholder="email"
                    onChange={(e) => onChange(e)}
                    className="form-control my-3"
                />
                <input
                    type="password"
                    name="password"
                    value={password}
                    placeholder="password"
                    onChange={(e) => onChange(e)}
                    className="form-control my-3"
                />
                <input
                    type="text"
                    name="name"
                    value={name}
                    placeholder="name"
                    onChange={(e) => onChange(e)}
                    className="form-control my-3"
                />
                <button className="btn btn-success btn-block">Submit</button>
            </form>
            <Link to="/login">login</Link>
        </Fragment>
    );
};

export default Register;
